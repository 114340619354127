@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

@import './variables';
@import './chat';

@import '~bootstrap/scss/bootstrap';

#notesTextArea{
  height: 100%;
}

.limited-text{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-ellipsis{
  text-overflow: ellipsis;
  overflow:hidden
}

.vertical-line{
  width: 2px;
  height: 100%;
  border: 1px solid #dedede;
}


.custom-radio input:checked{
  border-color: #f81942;
  outline: #f81942;
  background-color: red;
}

.custom-radio input:focus{
  box-shadow: 0 0 0 0.25rem rgba(248, 25, 66, 0.25);
  border-color: #f81942;

}

.react-datepicker__navigation--years-previous {
  width: 0 !important;
  height: 0 !important;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;

  border-top: 10px solid black !important;
  padding-bottom: 10px !important;
}

.react-datepicker__navigation--years-upcoming{
  width: 0 !important;
  height: 0 !important;
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;

  border-bottom: 10px solid black !important;
  padding-bottom: 10px !important;
}

.cursor-not-allowed{
  cursor: not-allowed;
}

.navbar{
  padding: 0.5rem 1rem;;
}

.text-danger{
  color: var(--bs-form-invalid-color);
}

.text-success{
  color: #26e28c;
}

.text-grey{
  color: #818181
}

.custom-tabs{
  &  .nav-link{
    color: #969696
  }
}

.no-public-bookings {
  &__title {
    color: #9D9D9D
  }
}

.public-bookings{
  border-style: solid !important;
  border-color: #dedede !important;
  border-width: 1px !important;
  border-radius: 12px !important;
}

.public-booking-row{
  border-bottom: solid 1px #D9D9D9;

  &:last-of-type{
    border-bottom: none;
  }

  &__date-time{
    color: #818181
  }

  &__copy-link{
    color: #1A9BF8;
  }

  &__actions{
    & .dropdown-item:active{
      background-color: #969696;
    }
  }
}

body {
  margin: 0;
  font-family: 'SF UI Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.accordion-item{
  &:first-of-type, &:last-of-type {
    border-radius: 12px;
  }

  &:not(:first-of-type){
    border-top: 1px solid #dedede;
  }
}

.text-right{
  text-align: right;
}


.float-right{
  margin-left: auto;
}

.accordion-item{
  border: none;
}

.col-12{
  padding-left: 15px;
  padding-right: 15px;
}

.accordion-button{
  &:after{
    display: none !important;
  }

  &:not(.collapsed){
    color: var(--bs-accordion-color);
    box-shadow: none;
  }
}

.pl{
  &-3{
    padding-left: 1rem!important;
  }

  &-4{
    padding-left: 1.5rem !important;
  }

  &-0{
    padding-left: 0 !important;
  }
}

.ml{

  &-1{
    margin-left: .2rem!important;
  }

  &-2{
    margin-left: .5rem!important;
  }

  &-3{
    margin-left: 1rem!important;
  }
}

.mr{
  &-2{
    margin-right: .5rem!important;
  }

  &-3{
    margin-right: 1rem!important;
  }

  &-4{
    margin-right: 1.5rem!important;
  }
}

.pr{
  &-4{
    padding-right: 1.5rem !important;
}

  &-0{
    padding-right: 0 !important;
  }
}

.btn-primary{
  color: white !important;
}

.btn-outline-dark{
  border-color: #000000;

  &:hover{
    background-color: #343a40;
    border-color: #343a40;
    color: white;
  }
}

a{
  text-decoration: none;
  &:hover{
    color: #0056b3
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #000000;
  &:hover {
    text-decoration: none;
  }
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.1rem;
}

.btn {
  border-radius: 22px;
}

.btn-secondary {
  border-color: map-get($theme-colors, 'primary');
  border-width: 1px;
  color: map-get($theme-colors, 'primary') !important;
}

.cursor-pointer{
  &:hover{
    cursor: pointer;
  }
}

.btn-outline-secondary{
  &:hover{
    background: #e6e6e6;
  }
}

.btn-tertiary {
  padding-top: 1px;
  padding-bottom: 1px;
}

.btn-green {
  background-color: map-get($theme-colors, 'green');
  color: map-get($theme-colors, 'secondary');
}

.btn-blue {
  background-color: map-get($theme-colors, 'blue');
  color: map-get($theme-colors, 'secondary');
}

.doctor-signature {
  border-color: map-get($theme-colors, 'primary');
  border-width: 1px;
  border-radius: 22px;
  border-style: solid;
}

.form-control {
  border-radius: 22px;
}

.form-label {
  color: #00000077;
  font-size: 0.8em;
}

.vcenter {
  display: flex;
  align-items: center;
}

.item-notification {
  border-style: solid;
  border-color: #00000077;
  border-width: 1px;
  border-radius: 12px;
  width: 100%;
  height: 60px;
  text-decoration: none;
  color: black;
}

.item-notification-no-border {
  width: 609px;
  height: 60px;
  text-decoration: none;
  color: black;
}

.empty-border {
  border-style: none;
  border-width: 0;
  border-radius: 0;
  border-color: transparent;
}

.item-notification:hover {
  text-decoration: none;
  color: black;
}

.pink-notification {
  background-color: #fc346e;
  padding: 4px;
  min-width: 30px;
  max-height: 30px;
  border-radius: 15px;
  color: #ffffff;
}

.blue-notification {
  background-color: #007cff;
  padding: 4px;
  min-width: 30px;
  max-height: 30px;
  border-radius: 15px;
  color: #ffffff;
}

.dashboard-border {
  border-color: #dedede;
  border-style: solid;
  border-width: 0.6px;
  border-radius: 22px;
}

.division-border {
  border-color: #dedede;
  border-style: solid;
  border-top-width: 0.6px;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.division-border-items {
  border-color: #8c8c8c;
  border-style: solid;
  border-top-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.appointment-item {
  border-color: #dedede;
  border-style: solid;
  border-top-width: 0.6px;
  border-bottom-width: 0.6px;
  border-left-width: 0;
  border-right-width: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.patient-border {
  border-color: #dedede;
  border-style: solid;
  border-width: 0.6px;
}

.is-plus {
  background-color: #11e158;
  width: 22px;
  height: 22px;
  border-radius: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-edit {
  background-color: #00b7f3;
  width: 22px;
  height: 22px;
  border-radius: 30px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell-day-button {
  display: flex;
  flex-direction: row;
  height: 58px;
  align-items: center;
  justify-content: space-between;
  border-style: solid;
  border-color: #e9e9e9;
  border-top-width: 0.5px;
  border-bottom-width: 0.5px;
  border-right-width: 0px;
  border-left-width: 0px;
  margin-bottom: 10px;
}

.day-view {
  display: flex;
  width: 20px;
  height: 30px;
  border-radius: 30px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  align-items: center;
  justify-content: center;
}
.invalid-feedback {
  margin-left: 15px;
}

.vertical-buffer {
  padding-bottom: 30px;
  padding-top: 30px;
}

.vertical-padding-body {
  padding-bottom: 4px;
  padding-top: 4px;
}

.vertical-margin-body {
  margin: 4px 0px 10px 0px;
}

.border-bottom-solid {
  border-bottom-width: 1px;
  border-bottom-color: gray;
  border-bottom-style: solid;
}

.border-top-solid {
  border-top-width: 1px;
  border-top-color: gray;
  border-top-style: solid;
}

.border-solid {
  border-width: 1px !important;
  border-color: gray !important;
  border-style: solid !important;
  border-radius: 0px;
}

.text-decoration-none {
  text-decoration: none;
  color: black;
}
.padding-10 {
  padding: 10px 10px 10px 10px;
}

.padding-h-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.padding-h-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.margin-10 {
  margin: 10px 10px 10px 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-0 {
  margin-bottom: 0px;
}

.margin-item-footer {
  margin-bottom: 3px;
}

.bound-image-medium {
  min-width: 80px;
  min-height: 80px;
}

.feedback-star {
  font-size: 30px;
  font-style: normal;
}

.vcenter-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.h-end-column {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.h-space-between-column {
  display: flex;
  justify-content: space-between;
}

.font-large {
  font-size: 30px;
}

.item-transactions {
  border-style: solid;
  border-color: #dedede;
  border-width: 1px;
  border-radius: 12px;
  margin: 10px 14px;
  padding: 0px 14px;
  text-decoration: none;
  color: black;
}

.accordion .card-header {
  background-color: inherit !important;
  border-bottom: 0px !important;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 400;
  border-radius: inherit !important;
}

.information-row-container {
  border: 0.5px solid gray;
  border-radius: 15px;
  padding: 20px 20px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 30px;
  margin-right: 5px;
}

.doctor-title-information {
  font-weight: bold;
  flex-direction: row;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.doctor-information {
  text-align: justify;
  font-size: 14px;
  color: gray;
}

.doctor-attr-name {
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 500;
}

.doctor-attr-value {
  font-weight: 500;
}

.center-footer {
  justify-content: center;
}

.btn-medium {
  min-width: 100px;
}

.btn-medium-v {
  height: 30px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.sub-title {
  color: #808080;
}

.patient-button {
  background-color: #00b7f3;
  border: 0px;
  color: #fff;
  &:hover {
    background-color: #448fe1;
    color: #fff;
  }
}

.nav-link {
  padding: 0.2rem;
}

.selected-date{
  background-color: #f81942 !important;
}

.time-picker{
  & input {
    height: auto;
    @extend .form-control
  }
}

.react-calendar {
  width: 285px !important;
  max-width: 285px !important;
  min-width: 285px !important;
  border: none !important;

  &__marked-date{
    position: relative;
    &:after{
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      background-color: #53da53;
      bottom: 10px;
      border-radius: 50%;
    }
  }

  &__year-view,
  &__decade-view,
  &__century-view {
    .react-calendar__tile {
      padding: 2em .5em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &__month-view {
    line-height: 0.3rem !important;

    &__days__day {
      font-size: 22px !important;
    }
  }

  &__tile {
    padding: 0.75em 0.5em !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;


    &--now{
      background-color: initial !important;

      &:hover:not(.react-calendar__tile--active){
        background-color: #e6e6e6 !important;
      }
    }

    &--hasActive {
      background: rgb(219, 56, 108) !important;
      border-radius: 30px;
    }

    &--active {
      background: #f81942 !important;
      color: white;
      border-radius: 30px;
    }
  }

  &__navigation{
    display: flex;
    &__label{
      font-size: 0.75em !important;
    }

    &__arrow{
      font-size: 22px !important;
    }
  }

}

//.react-calendar {
//  width: 285px !important;
//  max-width: 285px !important;
//  min-width: 285px !important;
//}
//
//.react-calendar__month-view {
//  line-height: 0.3rem;
//}
//
//.react-calendar__tile {
//  padding: 0.75em 0.5em !important;
//
//  > div {
//    position: absolute;
//    margin-top: 14px;
//  }
//}
//
//.react-calendar__tile--active {
//  background: #f81942 !important;
//}
//
//.react-calendar__navigation__label {
//  font-size: 0.75em;
//}
//
//.react-calendar__month-view__days__day {
//  font-size: 22px;
//}

.loader .modal-content {
  background-color: inherit;
  border: 0px;
}

.btn-leave {
  background: url('../../public/assets/phone.png') no-repeat;
  background-size: contain;
  height: 25px;
  width: 50px;
}

.right-panel-arrow {
  height: 50px;
  width: 50px;
  cursor: pointer;
  z-index: 3;
  position: fixed;
  color: #ffffff;
  transform: rotateY(180deg);
  @media screen and (min-width: 576px) {
    background: url('../../public/assets/right-arrow-light.png') no-repeat;
    right: 5%;
    top: 45%;
  }
  @media screen and (max-width: 575px) and (orientation: portrait) {
    background: url('../../public/assets/up-arrow-light.png') no-repeat;
    bottom: 0;
    right: 50%;
    margin-right: -25px;
  }

  &__side-open{
    left: 42%;
    transform: none;
  }
}

.close-panel-arrow {
  height: 75px;
  width: 100vw;
  z-index: 3;
  position: relative;
  color: #ffffff;
  cursor: pointer;
  @media screen and (min-width: 576px) {
    display: none;
  }
  @media screen and (max-width: 575px) and (orientation: portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &img {
    height: 50px;
    width: 50px;
  }
}

.btn-leave-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: map-get($theme-colors, 'primary');
  border-radius: 50px;
  position: fixed;
  margin: auto;
  outline: none;
  cursor: pointer;
  left: 5%;
  border: 0;
  bottom: 75px;
  z-index: 3;
  width: 65px;
  height: 65px;
}

.btn-plus-background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.137);
  border-radius: 50px;
  position: fixed;
  margin: auto;
  outline: none;
  cursor: pointer;
  right: 5%;
  bottom: 75px;
  z-index: 3;
  width: 65px;
  height: 65px;

  &__left{
    left: -10%;
  }
}

.btnVideoCall {
  &__grayTransparent {
    display: flex;
    // background-color: rgba(255, 255, 255, 0.247);
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 65px;
    height: 65px;
    z-index: 3;
    color: white;
  }
  &__plus {
    position: absolute;
    margin: auto;
    outline: none;
    @media screen and (max-width: 575px) and (orientation: portrait) {
      box-shadow: 0px 0px 2px 2px;
      right: 92px;
    }
    @media screen and (min-width: 576px) {
      box-shadow: 0px 0px 2px 2px;
      right: 5%;
    }
    bottom: 150px;
  }

  &__actions{
    position: sticky;
    margin: auto;
    outline: none;
    display: flex;
    justify-content: center;
    bottom: 75px;
  }

  &__outline {
    border: 2px solid white;
    &:hover{
      background-color: rgba(255, 255, 255, .4);;
    }
  }

  &__flipCamera{
    margin-left: 50px;


    @media screen and (max-width: 576px) {
      margin-left: 20px;

    }
  }

  &__pendingParticipantsList{
    position: relative;

    &-menu{
      border: 2px solid #dedede
    }

    &-wrapper{
      position: relative;
      margin-left: 50px;
    }

    &-list{
      position: absolute;
      background-color: white;
      bottom: 70px;
      right: 50%;
      white-space: nowrap;
      padding: 10px 15px;
      border-radius: 15px;
    }


    &-count{
      right: 0;
      top: -11px;
      width: 31px;
      height: 31px;
      padding: 0 4px;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      text-align: center;
      border-radius: 20px;
      background-color: white;
      border: 1px solid black;
      z-index: 3;
      cursor: pointer;
    }

    &-item{
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      white-space:nowrap;
      border-bottom: 1px solid #dedede;
      &:last-of-type{
        border-bottom: none;
      }
    }

    &-actions{
      display: flex;
      justify-content: center;
      margin-top: 10px;
      color: white;

      &__approve{
        background-color: #32CD32;
        padding: 2px 10px;
        border-radius: 10px;
        cursor: pointer;
      }

      &__decline{
        background-color: #FF0000;
        padding: 2px 10px;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 576px) {
      margin-left: 20px;

    }
  }

  &__micro{
    position: relative;

    &-off{
      background-color: map-get($theme-colors, 'primary') !important;
      border: none;

      &:after{
        content: '';
        position: absolute;
          width: 40px;
          height: 0;
          border-bottom: 3px solid white;
          transform: translateY(0) translateX(0px) rotate(40deg);
      }
    }
  }

  &__sidePanel {
    &__open {
      position: absolute;
      color: #ffffff;
      @media screen and (min-width: 576px) {
        box-shadow: 0px 0px 2px 2px;
        right: 5%;
        top: 41%;
        div {
          background: url('../../public/assets/right-arrow-light.png') no-repeat;
          width: 23px;
          height: 41px;
        }
      }
      @media screen and (max-width: 575px) and (orientation: portrait) {
        bottom: 150px;
        box-shadow: 0px 0px 2px 2px;
        right: 20px;
        div {
          background: url('../../public/assets/up-arrow-light.png') no-repeat;
          height: 23px;
          width: 41px;
        }
      }
    }
  }
}

.btn-full-view-background {
  border-color: #ffffff;
  color: #ffffff !important;
  background-color: transparent;
  position: fixed;
  cursor: pointer;
  margin: 0 auto;
  z-index: 3;
  width: 200px;
  height: 35px;

  &__left{
    margin-left: calc(25% - 100px)
  }

  @media screen and (min-width: 576px) {
    left: 0;
    right: 0;
    bottom: 20px;
  }
  @media screen and (max-width: 575px) and (orientation: portrait) {
    left: 0;
    right: 0;
    top: 10px;
    display: none;
  }
}

.video-call-title {
  color: #ffffff;
  position: absolute;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 3;
  width: 300px;
  height: 35px;
  @media screen and (max-width: 576px) {
   top: 40%;
  }
}

.local-track video {
  width: 15vw;
  z-index: 2;
  top: 5%;
  left: 5%;
  position: absolute;
  @media screen and (min-width: 576px) {
  }
  @media screen and (max-width: 575px) and (orientation: portrait) {
    width: 35%;
  }
}

.remote-black {
  height: 100vh;
  max-width: 100vw;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }
  @media screen and (orientation: landscape) {
    flex-direction: row;
  }
}
.remote-black-public {
  &__noMenuBar {
    height: 100vh;
    width: 100%;
    max-height: 100vh;
    max-width: 100vw;
  }
  &__menuBar {
    height: 94vh;
    width: 100vw;
    max-height: 94vh;
    max-width: 100vw;
  }
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }
  @media screen and (orientation: landscape) {
    flex-direction: row;
  }
}

.remote-track video {
  max-width: 100%;
  max-height: 100%;
  flex: 1;
  overflow: auto;
  pointer-events: none;
}
.remote-black video {
  overflow: auto;
}

.documents-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.video-center-message {
  background-color: #ffffff;
  position: absolute;
  top: 35%;
  left: 25%;
  width: 50%;
  height: 20%;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
}

.document-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 128px;
  word-wrap: break-word;
  overflow: hidden;
  margin-right: 8px;
}

.filename-text {
  font-size: 12px;
  margin: 5px;
}

.btn-countdown-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(92, 86, 87, 0.4);
  border-radius: 50%;
  position: absolute;
  margin: auto;
  outline: none;
  right: 2%;
  border: 0;
  top: 2%;
  z-index: 3;
  width: 100px;
  height: 100px;
  color: white;
  border-color: map-get($theme-colors, 'primary');
  border-style: solid;
  border-width: 2px;
  font-size: 30px;
  small {
    font-size: 8px;
  }
}

.icon-green {
  color: map-get($theme-colors, 'secondary');
}

.btn-under-border {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  border-block-color: black;
}

.icon-medium {
  border-radius: 10px;
  width: 20px;
  height: 20px;
  background-color: #a2d773;
  margin-left: 15px;
}

.modal-title {
  font-family: 'SF UI Text';
  width: 100%;
  text-align: center;
  font-size: 15px;
  line-height: 1.2;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.48px;
  color: #000000;
}

.affiliation-title {
  font-family: 'SF UI Text';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: -0.44px;
  color: #000000;
}

.sms-value {
  font-weight: bold;
  font-size: 17px;
}

.modal-org-body {
  margin: 10px 20px 20px 51px;
}

.short-bottom-separator {
  border-bottom-color: #c7c7cc;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.short-top-separator {
  border-top-width: 1px;
  border-top-color: #c7c7cc;
  border-top-style: solid;
}

.padding-vertical-10 {
  padding: 10px 0px 10px 0px !important;
}

.padding-vertical-20 {
  padding: 20px 0px 20px 0px !important;
}

.affiliation-sub-title {
  font-family: 'SF UI Text';
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.2;
  letter-spacing: -0.3px;
  text-align: left;
  color: #000000;
}

.margin-top-35 {
  margin-top: 35px;
}

.spacer-input-base {
  height: 1.5rem;
}

.modal-container-padding > div:first-of-type {
  padding: 10px 10px 10px 10px;
}

.modal-container-radius > div:first-of-type {
  border-radius: 35px !important;
}

.height-30 {
  height: 30px;
}

.modal-textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  height: 400px !important;

  font-family: 'SF UI Text';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #000000;
  padding-left: 25px;
}

.modal-footer-btn {
  padding-left: 3px;
  padding-right: 3px;
}

.plan-text {
  font-family: 'SF UI Text';
  // font-size: 12.5px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.76;
  letter-spacing: -0.25px;
  color: map-get($theme-colors, 'primary');
  height: 10px;
}

.price-title {
  font-family: 'SF UI Text';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.14;
  letter-spacing: -0.22px;
  color: #000000;
  height: 24px;
}

.price-descrption {
  opacity: 0.74;
  font-family: 'SF UI Text';
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.14;
  letter-spacing: -0.22px;
  color: rgba(0, 0, 0, 0.74);
  height: 29px;
}

.include-text {
  font-family: 'SF UI Text';
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.14;
  letter-spacing: -0.22px;
  color: #000000;
  height: 24px;
}

.include-value {
  opacity: 0.74;
  font-family: 'SF UI Text';
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.74);
  height: 11px;
}

.price-text {
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.14;
  letter-spacing: -0.22px;
  color: #000000;
  margin-top: 0px;
  margin-right: 16px;
}

.price-value {
  height: 18px;
  font-family: 'SF UI Text';
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.45;
  letter-spacing: -0.4px;
}

.price-money {
  color: #23e39d;
}

.payment-plan-pequest {
  opacity: 0.84;
  font-family: 'SF UI Text';
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: -0.46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.84);
}

.padding-h-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.padding-left-0 {
  padding-left: 0px;
}

.file-dropzone {
  cursor: pointer;
}

.file-dropzone:focus {
  outline: none;
}

.file-dropzone > .wrapper {
  position: relative;
  width: 162px;
}

.file-dropzone > .wrapper .fa-edit {
  position: absolute;
  top: 0;
  right: 0;
}

.terms-and-conditions {
  padding-left: 30px;
  padding-right: 30px;
  display: inline-block;
}

.full-screen-component {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
}
.full-screen-component-public {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
}

.border-image {
  border-radius: 0px 13px 13px 15px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.time-slot-toggle {
  padding: 0px 15px;
  font-size: 1rem !important;
  font-weight: bold !important;
}

.attrs svg {
  margin-left: 4px;
}

.refil-date {
  color: #67b2e1;
}

.page-max-width {
  max-width: 1364px !important;
}

.header-bottom-border {
  padding: 0 16px;
  border-bottom: 1px solid red;
  box-shadow: 0px 1px 1px -1px #ff000059;

  & .active {
    border: 1px solid map-get($theme-colors, 'primary');
    border-radius: 22px;
  }
}

.btn-medium-max {
  max-width: 150px !important;
}

.fill {
  height: 100%;
  min-height: 100%;
}

.center-text-align {
  text-align: center;
}

@media (max-width: 530px) {
  .patient-profile-query {
    flex-direction: column;
  }
}

.patient-profile-layout {
  padding: 15px;
}

.patient-image {
  width: 25%;
}

.patient-details {
  width: 75%;
  overflow-wrap: break-word;
}

.gray-background {
  background-color: #f5f6f7;
  height: 100%;
  z-index: 2;
}

.full-height {
  min-height: 100%;
}

.text-normal {
  color: black;
}

.text-normal-weight {
  font-weight: 500 !important;
}

.subscription-color {
  color: #23e39d;
}

.video-call-color {
  color: #00b7f3;
}

.link-color {
  color: rgba(2, 151, 255, 0.74);
}

.notes-videochat-wrapper {
  margin-top: 15px;
  height: 100%;
  max-height: calc(100vh - 170px);
  overflow-y: scroll;

  &__with-nav-bar {
    max-height: calc(100vh - 230px);
  }
}

.text-area-mixed-readonly {
  height: calc(100% - 10px);
  @keyframes textAreaFocus {
    from {
      outline-width: 0px;
    }
    to {
      outline-width: 3px;
    }
  }
  $text-area-border-color: rgb(219, 219, 219);
  border: 1px solid rgba(0, 0, 0, 0);
  box-sizing: content-box;
  flex-direction: column;
  color: rgb(71, 71, 71);
  &__selected {
    border-radius: 22px;
    outline-style: solid;
    outline-width: 0px;
    animation-name: textAreaFocus;
    animation-duration: 50ms;
    animation-fill-mode: forwards;
    outline-color: rgba(0, 131, 253, 0.336);
    border: 1px solid rgba(48, 147, 240, 0.849);
  }

  &__input-area {
    height: 70px;
  }

  &__section-title {
    padding-top: 5px !important;
    padding-bottom: 0px !important;
  }

  textarea {
    color: rgb(71, 71, 71);
    width: 100%;
    height: 100%;
    border-top: 0 none transparent;
    border-bottom: 0 none transparent;
    border-left: 1px $text-area-border-color solid;
    border-right: 1px $text-area-border-color solid;
    padding: 7px;
    resize: none;
    &:focus {
      outline: none;
    }
    &:disabled {
      border-top: 0 none transparent;
      border-bottom: 0 none transparent;
      resize: none;
      background-color: white;
    }
  }
  :first-child {
    border-top: 1px $text-area-border-color solid;
    border-top-right-radius: 22px;
    border-top-left-radius: 22px;
  }
  :last-child {
    overflow-y: hidden;
    border-bottom: 1px $text-area-border-color solid;
    border-bottom-right-radius: 22px;
    border-bottom-left-radius: 22px;
  }
}

.fix-notes-oversized {
  max-height: calc(min(900px, 80vh));
  overflow-y: auto;
}
