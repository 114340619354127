.chat {
  border-color: #dedede;
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;

  margin-bottom: 8px;
}

.row{
  align-items: start;
}

.msg-container {
  overflow: scroll;
  padding-top: 8px;
  max-width: 100%;
  min-width: 100%;
}

.msg-bubble {
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 8px;
  max-width: 80%;

  p {
    word-wrap: break-word;
  }

  &.received {
    background-color: #f5f6f7;
    border-bottom-left-radius: 0;
  }

  &.sent {
    background-color: #007bff;
    border-bottom-right-radius: 0;
    color: #ffffff;
  }

  & .msg-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.msg-bubble-img {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 8px;
  width: 60%;

  img {
    border-radius: 16px;
    object-fit: cover;
    width: 100%;
  }

  &.received {
    margin-right: 40%;

    img {
      border-bottom-left-radius: 0;
    }
  }

  &.sent {
    margin-left: 40%;

    img {
      border-bottom-right-radius: 0;
    }
  }

  & .msg-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-top: 4px;
    padding-right: 8px;
  }
}

@media (min-width: 576px) {
  .msg-bubble-img {
    width: 40%;

    &.received {
      margin-right: 60%;
    }

    &.sent {
      margin-left: 60%;
    }
  }
}

.send-btn {
  height: 2em;
  margin-top: auto;
  margin-bottom: auto;
  object-fit: contain;
  cursor: pointer;
}

.chatrooms:hover {
  background-color: #f8f8f8;
  color: #000;
}

.chatrooms .im-sender {
  color: #67b2e1;
  font-weight: 600;
}

.placeholder {
  flex: 1;
  background-color: unset !important;
}

.text-area{
  padding-left: 15px;
  padding-right: 15px
}

.msg-row {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
